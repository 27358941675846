import http from '../http';

export function projectList (params) {
    return http({
        url: "/api/project/myMemberProject",
        method: "get",
        params:params
    })
}
//项目验收列表
export function projectAcceptDetial (params) {
    return http({
        url: "/api/project/projectAcceptionPage",
        method: "get",
        params:params
    })
}
//验收列表详情
export function acceptListDetial (params) {
    return http({
        url: "/api/project/projectAcception",
        method: "get",
        params:params
    })
}

//验收地址随机码生成
export function acceptUrl (params) {
    return http({
        url: "/api/project/projectAcceptionUrl",
        method: "get",
        params:params
    })
}

//验收项目的功能清单
export function getFun (params) {
    return http({
        url: "/api/project/projectFunction",
        method: "get",
        params:params
    })
}

//验收项目 验收完成
export function acceptFinish (data) {
    return http({
        url: "/api/project/projectComplete",
        method: "post",
        data:data
    })
}

//验收项目 获取验证码
export function sendSms (data) {
    return http({
        url: "/api/common/sendSms",
        method: "post",
        data:data
    })
}

//验收项目 验证码验证
export function checkAcception (params) {
    return http({
        url: "/api/project/checkAcception",
        method: "get",
        params:params
    })
}

//验收单提交
export function subAccept (data) {
    return http({
        url: "/api/project/projectAcception",
        method: "put",
        data:data
    })
}

//满意度调查表提交
export function sendPleased (data) {
    return http({
        url: "/api/project/projectPleased",
        method: "post",
        data:data
    })
}

//获取当前满意度
export function getPleased (params) {
    return http({
        url: "/api/project/projectPleased",
        method: "get",
        params:params
    })
}

//验证满意度手机号
export function subPhone (params) {
    return http({
        url: "/api/project/checkPleased",
        method: "get",
        params:params
    })
}

//填完满意度调查表提交
export function subPleased (data) {
    return http({
        url: "/api/project/checkSavePleased",
        method: "post",
        data:data
    })
}

//获取项目报告
export function getReport (params) {
    return http({
        url: "/api/project/projectReportDetail",
        method: "get",
        params:params
    })
}

//任务添加
export function addTask (data) {
    return http({
        url: "/api/project/projectTask",
        method: "put",
        data:data
    })
}
//任务修改
export function editTask (data) {
    return http({
        url: "/api/project/projectTask",
        method: "post",
        data:data
    })
}
//任务删除
export function deleteTask (data) {
    return http({
        url: "/api/project/projectTask",
        method: "delete",
        data:data
    })
}
//任务添加 获取执行人
export function getPlanMenber (params) {
    return http({
        url: "/api/project/getPlanMemberList",
        method: "get",
        params:params
    })
}
//任务添加 获取任务分页
export function getTaskList (params) {
    return http({
        url: "/api/project/projectTaskPage",
        method: "get",
        params:params
    })
}
//任务确认开始
export function startTask (data) {
    return http({
        url: "/api/project/projectCheckTask",
        method: "post",
        data:data
    })
}
//任务添加 获取执行人
export function getTaskDetail (params) {
    return http({
        url: "/api/project/projectTask",
        method: "get",
        params:params
    })
}
//甘特图
export function getGantt (params) {
    return http({
        url: "/api/project/taskGantt",
        method: "get",
        params:params
    })
}
//项目文件列表
export function getFile (params) {
    return http({
        url: "/api/project/projectAttachment",
        method: "get",
        params:params
    })
}
//项目审批记录
export function getApprove (params) {
    return http({
        url: "/api/project/projectAuditRecord",
        method: "get",
        params:params
    })
}
//功能验收单下载
export function download (params) {
    return http({
        url: "/api/project/projectAcceptionExport",
        method: "get",
        params:params
    })
}
//进度报告列表
export function progressReportList (params) {
    return http({
        url: "/api/project/mp_report",
        method: "get",
        params:params
    })
}
//里程碑列表
export function MilestoneList (params) {
    return http({
        url: "/api/project/mp",
        method: "get",
        params:params
    })
}
//生成进度报告
export function productProgressReport (data) {
    return http({
        url: "/api/project/mp",
        method: "post",
        data:data
    })
}
//计划变更
export function subPlanChange (data) {
    return http({
        url: "/api/project/taskChange",
        method: "post",
        data:data
    })
}
//计划变更记录
export function planChangeList (params) {
    return http({
        url: "/api/project/taskChange",
        method: "get",
        params:params
    })
}


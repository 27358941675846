<template>
  <div style="width: 100%; height: 100%; background: white">
    <div v-if="screenWidth < 500">请在电脑浏览器打开该链接</div>
    <div style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; " v-else-if="aaa">
      <el-form
          label-width="100px"
          style="width: 30%; margin-top: -10%">
        <el-form-item>
          <h2 style="text-align: center; margin-bottom: 20px">项目功能验收</h2>
        </el-form-item>
        <el-form-item label="手机号" class="required">
          <el-input v-model="data.phone">
            <el-button slot="append" style="" @click="sendSms">获取短信验证码</el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="验证码" class="required">
          <el-input v-model="code"></el-input>
        </el-form-item>
        <el-form-item label="验收人姓名" class="required">
          <el-input v-model="data.name"></el-input>
        </el-form-item>
        <el-form-item label="工号">
          <el-input v-model="data.job_number"></el-input>
        </el-form-item>
        <el-form-item label="单位">
          <el-input v-model="data.organization"></el-input>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button @click="checkSms">进入验收</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="padding: 130px" v-else>
      <h2 style="text-align: center; margin-bottom: 30px">项目功能验收</h2>
      <!--table 组件 -->
      <el-table
          :data="tableData"
          :span-method="objectSpanMethod"
          border
          style="width: 100%; margin-top: 20px">
        <el-table-column
            prop="function_1"
            label="一级功能模块"
            width="180">
        </el-table-column>
        <el-table-column
            prop="function_2"
            label="二级功能模块">
        </el-table-column>
        <el-table-column
            prop="function_3"
            label="三级功能模块">
        </el-table-column>
        <el-table-column
            prop="function_4"
            label="四级功能模块">
        </el-table-column>
        <el-table-column
            label="是否通过">
          <template slot-scope="scope">
            <el-radio-group v-model="scope.row.pass">
              <el-radio label="0">否</el-radio>
              <el-radio label="1">是</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column
            label="备注说明">
          <template slot-scope="scope">
            <el-input v-model="scope.row.remark_accept"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center; margin-top: 30px">
        <el-button type="primary" @click="allPass">全部通过</el-button>
        <el-button type="primary" @click="subAccept">提交</el-button>
      </div>

    </div>
  </div>

</template>
<script>
import {getFun, subAccept, sendSms, checkAcception} from "@/api/project_management/my_project";

export default {
  props: [],
  components: {},
  data() {
    return {
      screenWidth: '',
      randomKey: 1,
      bbb: 1,
      code: '',
      aaa: true,
      data: {
        params: '',
        name: '',
        job_number: '',
        organization: '',
        phone: '',
        content_json: {},
      },
      //table 数据源
      tableData: [],
      //合并单元格数据
      firstLeve: [],  //第一列要合并的数据，每行合并数
      firstKey: 0,   //第一列指针，用于判断第几行合并数+1
      secondLeve: [],
      secondKey: 0,
      thirdLeve: [],
      thirdKey: 0,
    }
  },
  created() {
    this.getFun()
    this.screenWidth = window.screen.width
    console.log(this.screenWidth)
  },
  mounted() {

  },
  methods: {
    //获取手机验证码
    sendSms() {
      sendSms({phone: this.data.phone, type: 1}).then(res => {
        console.log(res)
        this.$message({
          message: res.msg,
          type: 'success'
        });
      })
    },
    //验证码验证
    checkSms() {
      //必填项验证
      if (!this.data.phone || !this.code || !this.data.name) {
        this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        })
        return false
      }
      checkAcception({params: this.$route.query.params, phone: this.data.phone, code: this.code}).then(res => {
        console.log(res)
        this.aaa = false
      })
    },
    //获取功能清单
    getFun() {
      let Base64 = require('js-base64').Base64
      let pro_id = JSON.parse(Base64.decode(this.$route.query.params))
      getFun(pro_id).then((result) => {
        this.tableData = []
        this.firstLeve = []
        this.firstKey = 0
        this.secondLeve = []
        this.secondKey = 0
        this.thirdLeve = []
        this.thirdKey = 0
        this.getTableData(result.data)
        this.getMergeData(this.tableData, this.firstLeve, this.firstKey, "function_1")
        this.getMergeData(this.tableData, this.secondLeve, this.secondKey, "function_2")
        this.getMergeData(this.tableData, this.thirdLeve, this.thirdKey, "function_3")
      })
    },
    //全部通过
    allPass() {
      console.log(this.tableData)
      for (let i = 0; i < this.tableData.length; i++) {
        // this.tableData[i].pass = '0'
        this.$set(this.tableData[i], "pass", '1');
      }
      this.randomKey = this.createRamdomKey()
    },
    //生成随机数
    createRamdomKey() {
      return Math.floor(Math.random() * (10, 1000000012313));
    },
    //提交验收
    subAccept() {
      let subData = {...this.data}
      subData.params = this.$route.query.params
      subData.content_json = JSON.stringify(this.tableData)
      subAccept(subData).then((result) => {
        console.log(result)
        this.$message({
          message: result.msg,
          type: 'success'
        });
      })
    },

    //tree数据拆解为表格数据
    getTableData(treeData) {
      for (let i = 0; i < treeData.length; i++) {
        if (treeData[i].children && treeData[i].children.length !== 0) {
          this.getTableData(treeData[i].children)
        } else {
          treeData[i].pass = "1"
          treeData[i].remark_accept = ''
          this.tableData.push(treeData[i])
        }
      }
    },
    //单元格合并
    objectSpanMethod({rowIndex, columnIndex}) {
      if (columnIndex === 0) {
        return [this.firstLeve[rowIndex], this.firstLeve[rowIndex] > 0 ? 1 : 0]
      }
      if (columnIndex === 1) {
        return [this.secondLeve[rowIndex], this.secondLeve[rowIndex] > 0 ? 1 : 0]
      }
      if (columnIndex === 2) {
        return [this.thirdLeve[rowIndex], this.thirdLeve[rowIndex] > 0 ? 1 : 0]
      }
    },
    //获取要合并的单元格
    getMergeData(tableData, leve, key, spanName) {
      //tableData为要处理的表格数据，leve为每一列要合并的数据，key为列指针，spanName为列名
      //获取需合并的数据
      for (let i = 0; i < tableData.length; i++) {
        if (i === 0) {
          leve.push(1)
          key = 0
        } else {
          if (tableData[i][spanName] && tableData[i][spanName] === tableData[i - 1][spanName]) {
            leve[key] += 1
            leve.push(0)
          } else {
            leve.push(1)
            key = i
          }
        }
      }
    },
  },
}
</script>

<style type="scss" scoped>

</style>